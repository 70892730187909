<template>
  <article id="wrap">

    <form action="pw02.html">

      <AppHeader theme="white">パスワードを忘れた方</AppHeader>

      <section class="contents">

        <div class="contentInner">

          <div class="white_bg_area2">

            <div class="mb_20">ご登録時のメールアドレスを入力して「送信」ボタンをタップしてください。<br>※新しいパスワードが発行されますので、お忘れになったパスワードはご利用できなくなります。</div>

            <div :class="{ 'form-group--error': $v.email.$error }">
              <label for="mail">メールアドレス</label>
              <div class="error" v-if="$v.email.$dirty && !$v.email.required">入力してください</div>
              <div class="error" v-if="$v.email.$dirty && !$v.email.maxLength">200文字以内で入力してください</div>
              <div class="error" v-if="$v.email.$dirty && !$v.email.email">不正なメールアドレスです</div>
              <input type="email" id="mail" placeholder="メールアドレスを入力してください" v-model.trim="email">
            </div>

            <div class="bt_form"><button type="button" value="send" @click="send">送信</button></div>

          </div><!-- .white_bg_area -->

        </div><!--.contentInner-->

      </section><!--.contents-->


      <AppFooter theme="yellow" fixed></AppFooter>

    </form>

  </article>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import {validationMixin} from 'vuelidate';
import {maxLength, required, email} from 'vuelidate/lib/validators';
export default {
  name: 'Password',
  components: {AppHeader, AppFooter},
  data () {
    return {
      email: ''
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      email: {
        required,
        maxLength: maxLength(200),
        email,
      },
    }
  },
  methods: {
    async send () {
      this.$v.$touch();
      console.log(this.$v);
      if (!this.$v.$error) {
        await this.$http.post('password_forget', {email: this.email});
        this.$router.push({name: 'password-send'});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#wrap {
  .form-group--error {
    input, select, textarea {
      border: 1px solid #ffd3ce;
      background-color: #ffd3ce;
    }
  }
  .error {
    font-size: 10rem;
    color: #ff7666;
    margin: 5px 0 -5px;
  }
}
</style>